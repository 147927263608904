import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import Register from "@components/Register"

const RegisterPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Home" />
        <div className="row">
          <div className="col-12 py-3 text-center">
            <Register />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query RegisterQuery {
    banner: allFile(filter: { name: { eq: "registration_1440x400" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default RegisterPage
