export const FORM_QUESTIONS = [
  {
    question: `Full Name`,
    placeholder: `Enter Full Name`,
    name: `full_name`,
    type: `text`,
    required: `Please enter your full name`,
  },
  {
    question: `Phone`,
    placeholder: `Enter Your Phone`,
    name: `phone`,
    type: `tel`,
    required: `Please enter your phone number`,
  },
  {
    question: `Email`,
    placeholder: `Enter Email`,
    name: `email`,
    type: `email`,
    required: `Please enter your email`,
  },
  {
    question: `Address`,
    placeholder: `Enter Your Address`,
    name: `address`,
    type: `text`,
    required: `Please enter your address`,
  },
  {
    question: `Date of birth`,
    placeholder: `Enter Your DOB`,
    name: `dob`,
    type: `date`,
    required: `Please enter your date of birth`,
    next: `height`,
  },
  {
    question: `Height`,
    placeholder: `Enter Your Height`,
    name: `height`,
    type: `height`,
    required: `Please enter your height`,
    id: `height`,
  },
  {
    question: `Body weight`,
    placeholder: `Enter Your Body Weight (in pounds)`,
    name: `weight`,
    type: `weight`,
    required: `Please enter your body weight in pounds`,
  },
  {
    question: `Do you know your blood type?`,
    placeholder: `Please enter your blood type`,
    name: `blood_type`,
    type: `blood_type`,
    required: `Please select or use Don't Know`,
  },
  {
    question: `Goal or contest body weight if you have one?`,
    placeholder: `Please describe or enter none`,
    name: `goal`,
    type: `textarea`,
    required: `Please describe your goal or contest body weight`,
  },
  {
    question: `Any illnesses?`,
    placeholder: `Please describe or enter none`,
    name: `illnesses`,
    type: `textarea`,
    required: `Please describe your illnesses if any`,
  },
  {
    question: `Any allergies?`,
    placeholder: `Please describe or enter none`,
    name: `allergies`,
    type: `textarea`,
    required: `Please describe your allergies if any`,
  },
  {
    question: `Any injuries?`,
    placeholder: `Please describe or enter none`,
    name: `injuries`,
    type: `textarea`,
    required: `Please describe your injuries if any`,
  },
  {
    question: `Any food you dislike?`,
    placeholder: `Please describe or enter none`,
    name: `food_dislikes`,
    type: `textarea`,
    required: `Please describe your food dislikes if any`,
  },
  {
    question: `Do you have any recent bloodwork results you can share?`,
    placeholder: `Please enter yes or no`,
    name: `blood_work`,
    type: `textarea`,
    required: `Please answer or put no`,
  },
  {
    question: `Are you taking any medications?`,
    placeholder: `Please describe or enter none`,
    name: `medications`,
    type: `textarea`,
    required: `Please describe your medications if any`,
    fullwidth: true,
  },
  {
    question: `Are you taking any sport aiding medications? If so please list amounts and when you take them.`,
    placeholder: `Please describe or enter none`,
    name: `super_supplements`,
    type: `textarea`,
    required: `Please describe if any`,
    fullwidth: true,
  },
  {
    question: `What sport supplements do you take? Please list amounts and when you take them.`,
    placeholder: `Please describe or enter none`,
    name: `supplements`,
    type: `textarea`,
    required: `Please describe if any`,
    fullwidth: true,
  },
  {
    question: `What do you consider your weakest muscle groups in terms of development?`,
    placeholder: `Please describe or enter none`,
    name: `weaknesses`,
    type: `textarea`,
    required: `Please describe if any`,
  },
  {
    question: `What days do you train (training split)?`,
    placeholder: `Please describe`,
    name: `training_split`,
    type: `textarea`,
    required: `Please describe your training split`,
  },
  {
    question: `What time(s) of day do you normally train?`,
    placeholder: `Please describe`,
    name: `training_time`,
    type: `textarea`,
    required: `Please describe your training time(s)`,
  },
  {
    question: `Do you have a training partner?`,
    placeholder: `Please describe`,
    name: `training_partner`,
    type: `textarea`,
    required: `Please answer yes or no`,
  },
  {
    question: `What time do you normally wake up and go to bed?`,
    placeholder: `Please describe`,
    name: `sleep_schedule`,
    type: `textarea`,
    required: `Please describe your sleep schedule`,
  },
  {
    question: `Are there any exercises which cause you pain?`,
    placeholder: `Please describe or enter none`,
    name: `exercise_pain`,
    type: `textarea`,
    required: `Please describe if any`,
  },
  {
    question: `What diet do you feel works well for you?`,
    placeholder: `High carb? Low carb? Medium carb? Carb cycling?`,
    name: `diet_preference`,
    type: `textarea`,
    required: `Please describe your diet preference`,
  },
  {
    question: `What type of cardio do you perform?`,
    placeholder: `Type? Length of time?`,
    name: `current_cardio`,
    type: `textarea`,
    required: `Please describe if any`,
  },
  {
    question: `What does your current diet look like? Please give full clarity.`,
    placeholder: `Please describe`,
    name: `current_diet`,
    type: `textarea`,
    required: `Please describe your current diet`,
    fullwidth: true,
  },
  {
    question: `Please send picture of exactly what your currently looking like in good lighting. Please take these pictures in Bikini or underwear.`,
    name: `pictures`,
    type: `file`,
    required: `Please attach at least 3x images (12x max)`,
    fullwidth: true,
  },
  {
    question: `Have you used a prep coach before? Please list and give your honest experience of the process and results. `,
    placeholder: `Please describe or enter none`,
    name: `prior_coach`,
    type: `textarea`,
    required: `Please describe if any`,
    fullwidth: true,
  },
  {
    question: `What do you do for a living? Please include your working hours and plus working days.`,
    placeholder: `Please describe`,
    name: `work_life`,
    type: `textarea`,
    required: `Please describe your job`,
    fullwidth: true,
  },
  {
    question: `Please very clearly in FULL detail describe what you have done in the past if you have competed (FULL diet, cardio, supplement and enhancement programs). If have not competed then just put any other details about your past routines.`,
    placeholder: `Please describe`,
    name: `full_past`,
    type: `textarea`,
    required: `Plese describe your past information`,
    fullwidth: true,
  },
]
