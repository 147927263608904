import React, { useState, Fragment } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import imageCompression from "browser-image-compression"

import { Button, RenderFormElement, Error, Success } from "@components/Form"
import Loader from "@components/Loader"
import { FORM_QUESTIONS } from "./questions"
import { SERVER_URL } from "@config"

const Register = () => {
  const [files, setFiles] = useState([])
  const [imgCount, setImgCount] = useState(0)
  // - - - - - - - - - - - - - - - - - - - - -
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const [err, setErr] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const { register, handleSubmit, errors, watch, control } = useForm()
  const watchAll = watch({ nest: true })

  const onSubmit = async data => {
    try {
      setSuccess(null)
      setErr(null)
      setLoading(true)
      setIsDisabled(true)
      const formData = new FormData()
      // attach data
      Object.keys(data).map(key => formData.append(key, data[key]))
      // attach images
      files.forEach(file => {
        formData.append("files[]", file[0], file[0].name)
      })
      // ship it
      const rawResponse = await fetch(`${SERVER_URL}/register`, {
        method: "POST",
        body: formData,
      })
      const status = rawResponse.status
      const content = await rawResponse.json()
      setIsDisabled(false)
      setLoading(false)
      switch (status) {
        case 200:
          setSuccess(content.message)
          navigate("/register/success")
          break
        case 403:
          setErr(content.message)
          break
        case 422:
          setErr(content.message)
          break
        case 500:
          setErr(`A server error occurred. Please try again.`)
          break
        default:
          setErr(`Oops. Something went wrong and we are not sure what.`)
          break
      }
    } catch (error) {
      console.error(`Register Error: ${error}`)
      setErr(
        `An error occurred and your information was not submitted. Please try again.`
      )
      setIsDisabled(false)
      setLoading(false)
    }
  }

  const onFilesAdded = async (file, type) => {
    const { name } = file[0]
    const fileExt = name
      .split(".")
      .pop()
      .toLowerCase()
    if (fileExt === "jpg" || fileExt === "png" || fileExt === "jpeg") {
      //! Try to compress file prior to upload
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        const compressedFile = await imageCompression(file[0], options)
        setFiles([...files, [compressedFile]])
        setImgCount(imgCount + 1)
        setErr("")
      } catch (error) {
        console.error("comporession error: ", error)
        setFiles([...files, file])
        setImgCount(imgCount + 1)
        setErr("")
      }
    } else {
      setErr("Must be .jpg or .png image file")
    }
  }
  const onFileRemove = index => {
    const temp = []
    for (let i = 0; i < files.length; i++) {
      if (i !== index) {
        temp.push(files[i])
      }
    }
    setFiles(temp)
  }

  return (
    <div className="container-fluid py-3">
      <div className="row">
        <div className="col-12">
          <h1>Register For Coaching</h1>
          <h6>
            <i>
              Please provide as much details as possible to your answers. Please
              list exactly what you eat in every meal, including when you take
              your supplements and what amounts in ml, mg or grams.
            </i>
          </h6>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-5">
        <div className="row">
          {FORM_QUESTIONS.map((item, i) => (
            <Fragment key={`${i}-register-question`}>
              {RenderFormElement(
                item,
                i,
                register,
                errors,
                onFilesAdded,
                onFileRemove,
                isDisabled,
                files,
                watchAll,
                control
              )}
            </Fragment>
          ))}
        </div>
        <div className="row">
          {loading ? (
            <div className="col-12 mt-2 text-center">
              <Loader
                message={
                  "Submission in progress - if you had a lot of images this can take a few moments"
                }
              />
            </div>
          ) : (
            <div className="col-12">
              <Button disabled={isDisabled} onClick={handleSubmit(onSubmit)}>
                Submit Registration
              </Button>
            </div>
          )}
          {success && (
            <div className="col-12 mt-4">
              <Success message={success} />
            </div>
          )}
          {err && (
            <div className="col-12 mt-4">
              <Error message={err} />
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export default Register
